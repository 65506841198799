


















import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    networks: [
      {
        icon: 'mdi-linkedin',
        url: 'https://www.linkedin.com/in/mathieu-mazal-51b130124/'
      },
      {
        icon: 'mdi-instagram',
        url: 'https://www.instagram.com/mathieumzl/'
      },
      {
        icon: 'mdi-artstation',
        url: 'https://www.artstation.com/mathieu_mazal'
      }
    ]
  }),
  methods: {
    onclick: function (link:string) {
      window.open(link, '_blank')
    }
  }
})
