







import Vue from 'vue'
import PagePro from '../components/PagePro.vue'

export default Vue.extend({
  name: 'Pro',

  components: {
    PagePro
  }
})
