var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-8 mb-4",attrs:{"flat":"","color":"transparent","align":"center","justify":"center"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mt-4 mb-3",attrs:{"cols":"12","md":"8","lg":"6"}},[_c('h1',{},[_vm._v(_vm._s(_vm.$t("PresentationHomePage.titrepro")))]),_c('p',{attrs:{"align":"center"}},[_c('br'),_vm._v(_vm._s(_vm.$t("PresentationHomePage.DescriptionPro.paragraph1"))+" "),_c('br'),_c('br'),_vm._v(_vm._s(_vm.$t("PresentationHomePage.DescriptionPro.paragraph2"))+" ")])])],1)],1),_c('v-card-text',{attrs:{"color":"transparent","min-height":"80%","align":"center"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2","md":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"hvr-logo",attrs:{"width":"70px","src":require("@/assets/logo-soft/enscape_50px_50px.png")}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v("Enscape")])])],1),_c('v-col',{attrs:{"cols":"2","md":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"hvr-logo",attrs:{"width":"70px","src":require("@/assets/logo-soft/vray_50px_50pxb.png")}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v("V-Ray")])])],1),_c('v-col',{attrs:{"cols":"2","md":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"hvr-logo",attrs:{"width":"70px","src":require("@/assets/logo-soft/rhino_50px_50px.png")}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v("Rhino")])])],1),_c('v-col',{attrs:{"cols":"2","md":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"hvr-logo",attrs:{"width":"70px","src":require("@/assets/logo-soft/archicad_50px_50px.png")}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v("Archicad")])])],1),_c('v-col',{attrs:{"cols":"2","md":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"hvr-logo",attrs:{"width":"70px","src":require("@/assets/logo-soft/revit_50px_50px.png")}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v("Revit")])])],1),_c('v-col',{attrs:{"cols":"2","md":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"hvr-logo",attrs:{"width":"70px","src":require("@/assets/logo-soft/photoshop_50px_50px.png")}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v("Photoshop")])])],1),_c('v-col',{attrs:{"cols":"2","md":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"hvr-logo",attrs:{"width":"70px","src":require("@/assets/logo-soft/indesign_50px_50px.png")}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v("InDesign")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }